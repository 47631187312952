// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konfirmering-js": () => import("./../../../src/pages/konfirmering.js" /* webpackChunkName: "component---src-pages-konfirmering-js" */),
  "component---src-pages-listor-js": () => import("./../../../src/pages/listor.js" /* webpackChunkName: "component---src-pages-listor-js" */),
  "component---src-pages-manifest-js": () => import("./../../../src/pages/manifest.js" /* webpackChunkName: "component---src-pages-manifest-js" */),
  "component---src-pages-punkter-js": () => import("./../../../src/pages/punkter.js" /* webpackChunkName: "component---src-pages-punkter-js" */),
  "component---src-pages-sa-rostar-du-js": () => import("./../../../src/pages/sa-rostar-du.js" /* webpackChunkName: "component---src-pages-sa-rostar-du-js" */),
  "component---src-pages-valsedlar-js": () => import("./../../../src/pages/valsedlar.js" /* webpackChunkName: "component---src-pages-valsedlar-js" */),
  "component---src-pages-varfor-rosta-js": () => import("./../../../src/pages/varfor-rosta.js" /* webpackChunkName: "component---src-pages-varfor-rosta-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */)
}

